import OrderFeed from '../../components/order-feed/OrderFeed';

const OrderFeedPage = () => {
  return (
    <>
     <OrderFeed/>
    </>
  );
};

export default OrderFeedPage;
